var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Slider',{attrs:{"ratio":"10by1","image":_vm.$options.filters.cdnUrl(_vm.firstSlide.image),"title":_vm.mode === 'stock' ? _vm.$t('products.title_stock') : _vm.$t('products.title')}}),_c('Navigation',{staticClass:"anchorbar bg-bg",attrs:{"pages":_vm.catAnchors,"responsive":{ layout: 'scroll' },"sticky":true,"width":"xxl"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',{staticClass:"input-group product__search"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.search),expression:"search",modifiers:{"trim":true}}],staticClass:"-s",attrs:{"type":"text"},domProps:{"value":(_vm.search)},on:{"change":_vm.onSearch,"input":function($event){if($event.target.composing)return;_vm.search=$event.target.value.trim()},"blur":function($event){return _vm.$forceUpdate()}}}),_c('button',{staticClass:"-icon -s bg-white",attrs:{"type":"button"},on:{"click":_vm.onSearch}},[_c('icon',{staticClass:"mdi mdi-magnify"})],1)])]},proxy:true}])}),_c('div',{staticClass:"theme-light bg-white pagebg"},[_vm._l((_vm.cats),function(cat){return _c('div',{key:'productcat_' + cat.cat.id,staticClass:"c w-xxl pt-xxl",attrs:{"id":_vm.kebabcase(cat.cat['name_' + _vm.lang])}},[_c('h2',{staticClass:"color-accent products__title"},[_vm._v(" "+_vm._s(cat.cat['name_' + _vm.lang])+" ")]),_c('div',{staticClass:"flex -wrap columns"},[(cat.cat['text_' + _vm.lang])?_c('div',{staticClass:"col-12 col-6__s-up col-4__l-up mb-0 color-primary"},[_vm._v(" "+_vm._s(cat.cat['text_' + _vm.lang])+" ")]):_vm._e(),_vm._l((cat.items),function(item){return _c('div',{key:'item_' + item.id + '_cat_' + cat.cat.id,staticClass:"col-12 col-6__s-up col-4__l-up mb-0",class:{ 'text-center': _vm.mode === 'products' }},[_c('Highlight',{attrs:{"title":item['title_' + _vm.lang] || item.name,"image":item.image1 ? {
                        path: _vm.$options.filters.cdnUrl(item.image1),
                        ratio: '4by3',
                        button: {
                            title: _vm.$t('news.view'),
                        }
                    } : false},on:{"imageClick":function($event){return _vm.onImageClick(item)}},scopedSlots:_vm._u([(_vm.mode === 'stock')?{key:"content",fn:function(){return [_c('div',{staticClass:"product__stocksubtitle color-primary mt-0 weight-b text-uppercase"},[_vm._v(_vm._s(item.bundle)+" - "+_vm._s(_vm.$tc('products.amount', item.amount_available)))]),_c('div',{staticClass:"product__specs flex -wrap -align-start -justify-stretch size-6 color-primary"},[(item.size)?_c('div',{staticClass:"mr-xl -flex-grow pt-s"},[_c('div',{staticClass:"size-7 weight-b"},[_vm._v(_vm._s(_vm.$t('products.dimensions')))]),_vm._v(" "+_vm._s(item.size)+" ")]):_vm._e(),(item.thickness)?_c('div',{staticClass:"mr-xl -flex-grow pt-s"},[_c('div',{staticClass:"size-7 weight-b"},[_vm._v(_vm._s(_vm.$t('products.depth')))]),_vm._v(" "+_vm._s(item.thickness)+" ")]):_vm._e(),(item['finishing_' + _vm.lang])?_c('div',{staticClass:"mr-xl -flex-grow pt-s"},[_c('div',{staticClass:"size-7 weight-b"},[_vm._v(_vm._s(_vm.$t('products.finish')))]),_vm._v(" "+_vm._s(item['finishing_' + _vm.lang])+" ")]):_vm._e()])]},proxy:true}:null],null,true)})],1)})],2)])}),(!_vm.cats.length && _vm.search)?_c('div',{staticClass:"flex -justify-center"},[_c('CenteredText',{attrs:{"title":_vm.$t('articles.empty_title'),"text":_vm.$t('articles.empty_text')}})],1):_vm._e()],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }