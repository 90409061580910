<template>
    <div>
        <Slider ratio="10by1" :image="$options.filters.cdnUrl(firstSlide.image)"
            :title="mode === 'stock' ? $t('products.title_stock') : $t('products.title')" />

        <Navigation class="anchorbar bg-bg" :pages="catAnchors" :responsive="{ layout: 'scroll' }" :sticky="true"
            width="xxl">
            <template v-slot:content>
                <div class="input-group product__search">
                    <input type="text" v-model.trim="search" @change="onSearch" class="-s" />
                    <button type="button" @click="onSearch" class="-icon -s bg-white">
                        <icon class="mdi mdi-magnify " />
                    </button>
                </div>
            </template>
        </Navigation>

        <div class="theme-light bg-white pagebg">

            <div v-for="cat in cats" :key="'productcat_' + cat.cat.id" class="c w-xxl pt-xxl"
                :id="kebabcase(cat.cat['name_' + lang])">
                <h2 class="color-accent products__title">
                    {{ cat.cat['name_' + lang] }}
                </h2>

                <div class="flex -wrap columns">
                    <div class="col-12 col-6__s-up col-4__l-up mb-0 color-primary" v-if="cat.cat['text_' + lang]">
                        {{ cat.cat['text_' + lang] }}
                    </div>
                    <div v-for="item in cat.items" :key="'item_' + item.id + '_cat_' + cat.cat.id"
                        class="col-12 col-6__s-up col-4__l-up mb-0" :class="{ 'text-center': mode === 'products' }">
                        <Highlight :title="item['title_' + lang] || item.name" :image="item.image1 ? {
                            path: $options.filters.cdnUrl(item.image1),
                            ratio: '4by3',
                            button: {
                                title: $t('news.view'),
                            }
                        } : false" @imageClick="onImageClick(item)">
                            <template v-slot:content v-if="mode === 'stock'">
                                <div class="product__stocksubtitle color-primary mt-0 weight-b text-uppercase">{{
                                    item.bundle }} - {{ $tc('products.amount', item.amount_available) }}</div>
                                <div class="product__specs flex -wrap -align-start -justify-stretch size-6 color-primary">
                                    <div class="mr-xl -flex-grow pt-s" v-if="item.size">
                                        <div class="size-7 weight-b">{{ $t('products.dimensions') }}</div>
                                        {{ item.size }}
                                    </div>
                                    <div class="mr-xl -flex-grow pt-s" v-if="item.thickness">
                                        <div class="size-7 weight-b">{{ $t('products.depth') }}</div>
                                        {{ item.thickness }}
                                    </div>
                                    <div class="mr-xl -flex-grow pt-s" v-if="item['finishing_' + lang]">
                                        <div class="size-7 weight-b">{{ $t('products.finish') }}</div>
                                        {{ item['finishing_' + lang] }}
                                    </div>
                                </div>
                            </template>
                        </Highlight>
                    </div>
                </div>

            </div>
            <div v-if="!cats.length && search" class="flex -justify-center">
                <CenteredText :title="$t('articles.empty_title')" :text="$t('articles.empty_text')" />
            </div>
        </div>

    </div>
</template>

<script>
import kebabcase from 'lodash.kebabcase';

export default {
    name: 'Products',
    components: {
        CenteredText: () => import('@/components/CenteredText.vue'),
        Highlight: () => import('@/components/Highlight.vue'),
        Navigation: () => import('@/components/ui/Navigation.vue'),
        Slider: () => import('@/components/Slider.vue'),
    },
    props: {
        mode: {
            type: String,
            default: 'products',
        },
    },
    data() {
        return {
            cats: [],
            items: [],
            search: '',
        };
    },

    computed: {
        catAnchors() {
            return this.cats.map((cat) => ({
                name: cat.cat[`name_${this.lang}`],
                to: { hash: kebabcase(cat.cat[`name_${this.lang}`]) },
            }));
        },

        lang() {
            return this.$store.getters['Language/current'];
        },

        firstSlide() {
            if (this.$store.state.data.slides && this.$store.state.data.slides.length > 0) {
                return this.$store.state.data.slides[0];
            }
            return {};
        },

        texts() {
            return this.$store.state.data;
        },

        categories() {
            return this.texts.products_categories || [];
        },
    },

    methods: {
        kebabcase,

        init() {
            if (this.categories) {
                if (this.mode === 'products') {
                    this.$store.dispatch('get', { page: 'items/products/', params: { count: 500 } }).then((r) => {
                        this.items = r;
                        this.groupProducts('init');
                    });
                } else if (this.mode === 'stock') {
                    this.$store.dispatch('get', { page: 'items/stock/', params: { sort_by: 'id', sort_az: false, count: 500 } }).then((r) => {
                        this.items = r;
                        this.groupProducts('init');
                    });
                }
            } else {
                setTimeout(this.init, 1000);
            }
        },

        onImageClick(item) {
            const images = [];
            for (let i = 0; i < 10; i += 1) {
                if (item[`image${i}`]) {
                    images.push(this.$options.filters.cdnUrl(item[`image${i}`]));
                }
            }
            if (images.length) {
                this.$store.commit('updateOverlay', images);
            }
        },

        onSearch() {
            this.search = this.search.toLowerCase();
            this.groupProducts('search');
        },

        groupProducts(method) {
            const groupedByCat = {};
            const cats = this.categories;
            const catsOrder = cats.map((it) => it.id);

            const getCatInfo = (catID) => {
                const cat = cats.find((it) => it.id === catID);
                if (cat) {
                    return cat;
                }
                return false;
            };
            const addToCategory = (catID, product, cat) => {
                if (!groupedByCat[catID]) {
                    if (!cat) {
                        cat = getCatInfo(catID);
                    }
                    if (cat) {
                        groupedByCat[catID] = { items: [product], cat };
                    }
                } else {
                    groupedByCat[catID].items.push(product);
                }
            };
            const addToOthersCategory = (product) => {
                addToCategory('other', product, {
                    id: 'other',
                    name_nl: 'Overig',
                    name_en: 'Other',
                });
            };
            const addToNewCategory = (product) => {
                addToCategory('new', product, {
                    id: 'new',
                    name_nl: 'Nieuw binnen',
                    name_en: 'New arrivals',
                });
            };

            // Loop through items
            this.items.forEach((product, index) => {
                if (method !== 'search' || !this.search || `${(product.title_nl || '').toLowerCase()}_${(product.title_en || '').toLowerCase()}_${(product.name || '').toLowerCase()}`.includes(this.search)) {
                    if (product.category) {
                        addToCategory(product.category, product);
                    } else {
                        addToOthersCategory(product);
                    }
                    if (this.mode === 'stock' && index < 12) {
                        addToNewCategory(product);
                    }
                }
            });

            // Sort by category
            const results = [];
            if (groupedByCat.new) { results.push(groupedByCat.new); }
            catsOrder.forEach((id) => {
                if (groupedByCat[id]) {
                    results.push(groupedByCat[id]);
                }
            });
            if (groupedByCat.other) { results.push(groupedByCat.other); }
            this.cats = results;
        },
    },

    watch: {
        mode: {
            handler: 'init',
            immediate: true,
        },
    },
};
</script>
